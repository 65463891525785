<template>
    <div style="overflow-x: hidden">
        <load-screen-component :is-loading="isLoading"></load-screen-component>
        <page-header pageTitle="PROPOSAL.INDEX"
                     filter
                     @filter="setModalFilter"
        ></page-header>
        <div class="card p-1 mb-1" v-if="!items?.validations && items.data?.length">
            <div class="row mw-100">
                <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 align-self-center">
                    <div class="tags-block mt-1 ml-2 row left-content">
                        <div class="tags">
                            <div class="mr-2">
                                <i class="icon-label" :style="{'color':translateColorStatus('APROVADA')}"></i>
                                {{ translateStatus('APROVADA') }}
                            </div>
                        </div>
                        <div class="tags">
                            <div class="mr-2">
                                <i class="icon-label" :style="{'color':translateColorStatus('EM AVALIAÇÃO')}"></i>
                                {{ translateStatus('EM AVALIAÇÃO') }}
                            </div>
                        </div>
                        <div class="tags">
                            <div class="mr-2">
                                <i class="icon-label" :style="{'color':translateColorStatus('REJEITADA')}"></i>
                                {{ translateStatus('REJEITADA') }}
                            </div>
                        </div>
                        <div class="tags">
                            <div class="mr-2">
                                <i class="icon-label" :style="{'color':translateColorStatus('VENDA CANCELADA')}"></i>
                                {{ translateStatus('VENDA CANCELADA') }}
                            </div>
                        </div>
                        <div class="tags">
                            <div class="mr-2">
                                <i class="icon-label" :style="{'color':translateColorStatus('EXPIRADA')}"></i>
                                {{ translateStatus('EXPIRADA') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-2 text-right pr-0">
                    <button data-cy="lot_index_dropdown" class="btn btn-custom bg-btn no-border"
                            type="button"
                            id="import-buttom"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="icon-menu"/>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right dropdown-black dropdown-lot w-auto"
                         id="dropdown-import">
                        <div class="dropdown-item bg-hover-black w-auto">
                            <a class="row item-custom2 ml-1 text-white pr-2"
                               @click="exportInPdf">
                                <i class="icon-download mr-1 font-15"/>
                                Exportar em PDF
                            </a>
                        </div>
                        <div class="dropdown-item bg-hover-black w-auto">
                            <a class="row item-custom2 ml-1 text-white pr-2"
                               @click="exportInXlsx">
                                <i class="icon-download mr-1 font-15"/>
                                Exportar em XLSX
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-1" v-if="hastwoAllotments">
            <div class="card-body pt-2 row">
                <div class="col-10 col-xl-3 col-md-3">
                    <label for="state">{{ t('NAV.ALLOTMENT') }}</label>
                    <Select2 :settings="{ width: '100%', placeholder:  t('LOTS.ALLOTMENT')}" :options="allotments"
                             v-model="allotmentId" @select="changeAllotment($event)"/>
                </div>
            </div>
        </div>
        <div class="card" v-if="!items?.validations && items.data?.length && !widthMd(575)">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-hover table-bordered" style="overflow: hidden;">
                        <thead>
                        <tr>
                            <th class="text-center">N°</th>
                            <th class="text-center">{{ this.t('PROPOSAL.CLIENT') }}</th>
                            <th class="text-center">{{ this.t('PROPOSAL.STATUS') }}</th>
                            <th class="text-center">{{ this.t('PROPOSAL.REALTOR') }}</th>
                            <th class="text-center">Q.</th>
                            <th class="text-center">L.</th>
                            <th class="text-center">Assinatura</th>
                            <th class="text-center">{{ this.t('PROPOSAL.DATE_CREATE') }}</th>
                            <th class="text-center">{{ this.t('PROPOSAL.EXPIRED') }}</th>
                            <th class="text-center">{{ this.t('PROPOSAL.IS_IMPRESS') }}</th>
                            <th class="text-center">{{ this.t('PROPOSAL.INFO') }}</th>
                            <th class="text-center">
                                <div class="d-flex flex-row">
                                    <p>Ações</p>

                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(iten, key) in items.data" :key="iten.id">
                            <td class="text-center pt-2 pb-2">{{ getNumberProposal(iten) }}</td>
                            <td class="text-center pt-2 pb-2">{{ getNameClient(iten) }}</td>
                            <td class="text-center pt-2 pb-2">
                                <div class="font-weight-bold d-flex justify-content-center w-100"
                                     style="width: 130px;" id="status">
                                    <div class="rounded-pill pb-1 pt-1 card m-0 pl-3 pr-3"
                                         style="min-width: 130px"
                                         :style="{'background-color': translateColorStatus(iten.status) ,'color': 'white'}">
                                        {{ this.translateStatus(iten.status) }}
                                    </div>
                                </div>
                            </td>
                            <td class="text-center pt-2 pb-2">{{ iten.user_name ? iten.user_name : 'Indefinido' }}</td>
                            <td class="text-center pt-2 pb-2">{{ iten.properties?.block }}</td>
                            <td class="text-center pt-2 pb-2">{{ iten.properties?.name }}</td>
                            <td class="text-center pt-2 pb-2">
                                <div v-if="iten?.click_sign_documents?.status"
                                     :style="{'background-color': translateColorStatusAssign(iten?.click_sign_documents?.status), 'margin-bottom': '0'}"
                                     class="card text-white font-weight-bold p-1" style="font-size: 12px">
                                    {{ getStatusClickSing(iten?.click_sign_documents?.status) }}
                                </div>
                                <div v-else style="font-size: 12px"
                                >{{ getStatusClickSing(iten?.click_sign_documents?.status) }}
                                </div>
                            </td>
                            <td class="text-center pt-2 pb-2">{{ endDateFormat(iten.created_at, true) }}</td>
                            <td class="text-center pt-2 pb-2">{{ endDateFormat(iten.end_date) }}</td>
                            <td class="text-center pt-2 pb-2">
                                <i class="icon-circle pointer" style="font-size: 18px"
                                   title="Marcar como Impressa"
                                   v-if="!iten.is_impress" @click="markImpress(iten)"></i>
                                <i class="icon-check-circle pointer" style="font-size: 18px; color: #0e5e4e"
                                   title="Marcar como não impressa"
                                   @click="markImpress(iten)"
                                   v-else></i>
                            </td>
                            <td class="text-center pt-2 pb-2">
                                <div id="dropInfo w-100">
                                    <div class="dropdown" @mouseout="setModalHoover(`dropdownMenu${key}`)"
                                         @mouseover="setModalHoover(`dropdownMenu${key}`)">
                                        <i class="icon-info-with-circle"
                                           :id="`dropdownMenu${key}`"
                                           data-toggle="dropdown"
                                           aria-haspopup="true"
                                           aria-expanded="false"></i>
                                        <div class="dropdown-menu p-2" :aria-labelledby="`dropdownMenu${key}`"
                                             style="min-width: 40rem;">
                                            <div class="row mb-2 p-2">
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                    <div class="font-weight-bolder mt-2 ml-4 text-left">
                                                        {{ this.t('PROPOSAL.PARCEL_CONFIG.TYPE') }}
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                    <div class="font-weight-bolder mt-2 ml-4 text-left">
                                                        {{ this.t('PROPOSAL.PARCEL_CONFIG.VALUE') }}
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                    <div class="font-weight-bolder mt-2 text-left">
                                                        {{ this.t('PROPOSAL.PARCEL_CONFIG.NUMBER_PARCELS') }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-for="(parcel, key) in iten?.parcel_configs" :key="key"
                                                 :class="key != 0 ? 'border-top2': ''">
                                                <div class="row mw-100 space-between mt-2">
                                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                        <div class="list-primary hide-text">
                                                            <div class="w-auto">
                                                                {{ parcel.name }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                        <div class="list-primary hide-text">
                                                            <div class="w-auto">
                                                                {{ toCurrency(parcel.value) }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                        <div class="list-primary hide-text">
                                                            <div class="w-auto">
                                                                {{ parcel.number_parcels }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center pt-2 pb-2">
                                <div>
                                    <a type="button" data-toggle="dropdown" aria-haspopup="true"
                                       aria-expanded="false" class="w-50"
                                       style="font-size: 20px" id="listDropdown">
                                        <i class="icon-more-horizontal" title="Opções"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-black w-auto">
                                        <div class="dropdown-item pointer text-white"
                                             @click="reprintProposal(iten)">
                                            <i class="icon-eye1 mr-2 font-15"/>{{ t('PROPOSAL.VIEW') }}
                                        </div>
                                        <div class="dropdown-item pointer text-white"
                                             v-if="iten.status === 'EM AVALIAÇÃO' && !isRealtor()"
                                             @click="rejectProposal(iten)">
                                            <i class="icon-thumbs-down mr-2 font-15"/>{{ t('PROPOSAL.REJECT') }}
                                        </div>
                                        <div class="dropdown-item pointer text-white"
                                             data-toggle="modal" data-target="#customModalTwo"
                                             v-if="iten.status === 'EM AVALIAÇÃO' && !isRealtor()"
                                             @click="setModalEdit(iten.id)">
                                            <i class="icon-pencil mr-2 font-15"/>{{ t('PROPOSAL.EDIT') }}
                                        </div>
                                        <div class="dropdown-item pointer text-white"
                                             v-if="(iten.status === 'EM AVALIAÇÃO' && !iten.hasSignature ) ||
                             (iten.status === 'APROVADA' && !iten.hasSignature)"
                                             @click="setFile(iten.id)">
                                            <i class="icon-attach_file mr-2 font-15"/>{{
                                                t('PROPOSAL.SOLICIT_ASSIGN_MANUAL')
                                            }}
                                        </div>
                                        <div class="dropdown-item pointer text-white"
                                             v-if="(iten.status !== 'APROVADA' &&
                                             iten.hasSignature &&
                                             isAssingManual(iten.click_sign_documents) &&
                                             !isRealtor())"
                                             @click="deleteAssignManual(iten.id)">
                                            <i class="icon-trash mr-2 font-15"/>{{
                                                t('PROPOSAL.DELETE_ASSIGN_MANUAL')
                                            }}
                                        </div>
                                        <div class="dropdown-item pointer text-white"
                                             v-if="(iten.status === 'EM AVALIAÇÃO'  && hasConfig && canSocityClickSign(iten?.click_sign_documents?.status)) ||
                             (iten.status === 'APROVADA'  && hasConfig && canSocityClickSign(iten?.click_sign_documents?.status))"
                                             @click="solicitClickSing(iten.id)">
                                            <i class="icon-border_color mr-2 font-15"/>{{
                                                t('PROPOSAL.SOLICIT_CLICKSIGN')
                                            }}
                                        </div>
                                        <div class="dropdown-item pointer text-white"
                                             v-if="(iten.status === 'EM AVALIAÇÃO' && !isRealtor() && hasConfig && !isAssingManual(iten.click_sign_documents) && iten.click_sign_documents?.status === 1) ||
                             (iten.status === 'APROVADA' && !isRealtor()  && hasConfig && !isAssingManual(iten.click_sign_documents) && iten.click_sign_documents?.status === 1)"
                                             @click="cancelClickSing(iten.id)">
                                            <i class="icon-x-circle mr-2 font-15"/>{{ t('PROPOSAL.CANCEL_CLICKSIGN') }}
                                        </div>
                                        <div class="dropdown-item pointer text-white"
                                             data-toggle="modal" data-target="#customModalTwo"
                                             v-if="(iten.click_sign_documents?.clicksign_id || isAssingManual(iten.click_sign_documents)) && (hasConfig || isAssingManual(iten.click_sign_documents))"
                                             @click="viewDocumentClickSing(iten?.click_sign_documents?.clicksign_id, iten)">
                                            <i class="icon-eye mr-2 font-15"/>{{
                                                isAssingManual(iten.click_sign_documents) ? t('PROPOSAL.VIEW_CLICKSIGN') : 'Assinaturas'
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div v-if="!items?.validations && items.data?.length && widthMd(575)">
            <div v-if="(items && items.data)">
                <div class="card" v-for="(iten) in  items.data" :key="iten.id">
                    <div class="pd-top-left-right pdt-1 aling justify-content-between">
                        <div>
                            <div class="form-group">
                                <label class="mb-2"><b>{{ t('RESERVE.STATUS') }}</b></label>
                                <div>
                                    <div class="text-left font-weight-bold "
                                         style="margin-bottom: -2px; width: 120px; text-align: justify" id="status">
                              <span class="rounded-pill pb-1 pt-1 text-center card"
                                    :style="{'background-color': translateColorStatus(iten.status) ,
                                       'color': 'white'}">
                                 {{ this.translateStatus(iten.status) }}
                              </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <div class="form-group">
                                <label class="mb-2"><b>{{ t('PROPERTIES.BLOCK') }}</b></label>
                                <div>{{ iten.properties?.block }}</div>
                            </div>
                        </div>
                        <div class="text-center">
                            <div class="form-group">
                                <label class="mb-2"><b>{{ t('PROPERTIES.NAME') }}</b></label>
                                <div>{{ iten.properties?.name }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="pd-top-left-right aling justify-content-between">
                        <div>
                            <div class="form-group">
                                <label class="mb-2"><b>{{ t('RESERVE.REALTOR') }}</b></label>
                                <div>{{ iten.user_name ? iten.user_name : 'Indefinido' }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="pd-top-left-right aling justify-content-between">
                        <div>
                            <div class="form-group">
                                <label class="mb-2"><b>{{ t('RESERVE.CLIENT') }}</b></label>
                                <div>{{ getNameClient(iten) }}</div>
                            </div>
                        </div>

                    </div>
                    <div class="pd-top-left-right aling justify-content-between">
                        <div>
                            <div class="form-group">
                                <label class="mb-2"><b>N°</b></label>
                                <div> {{ getNumberProposal(iten) }}</div>
                            </div>
                        </div>
                        <div>
                            <div class="form-group">
                                <label class="mb-2"><b>{{ this.t('PROPOSAL.EXPIRED') }}</b></label>
                                <div> {{ endDateFormat(iten.end_date) }}</div>
                            </div>
                        </div>
                        <div class="text-center">
                            <div class="form-group">
                                <label class="mb-2"><b>{{
                                        t('ACTIONS.LABEL')
                                    }}</b></label>
                                <div>
                                    <a type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                       style="font-size: 24px">
                                        <i class="icon-more_horiz"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-black dropdown-reserve"
                                         style="min-width: min-content">
                                        <div class="dropdown-item pointer text-white"
                                             @click="reprintProposal(iten)">
                                            <i class="icon-eye1 mr-2 font-15"/>{{ t('PROPOSAL.VIEW') }}
                                        </div>
                                        <div class="dropdown-item pointer text-white"
                                             v-if="iten.status === 'EM AVALIAÇÃO' && !isRealtor()"
                                             @click="rejectProposal(iten)">
                                            <i class="icon-thumbs-down mr-2 font-15"/>{{ t('PROPOSAL.REJECT') }}
                                        </div>
                                        <div class="dropdown-item pointer text-white"
                                             data-toggle="modal" data-target="#customModalTwo"
                                             v-if="iten.status === 'EM AVALIAÇÃO' && !isRealtor()"
                                             @click="setModalEdit(iten.id)">
                                            <i class="icon-pencil mr-2 font-15"/>{{ t('PROPOSAL.EDIT') }}
                                        </div>
                                        <div class="dropdown-item pointer text-white"
                                             v-if="(iten.status === 'EM AVALIAÇÃO' && !iten.hasSignature ) ||
                             (iten.status === 'APROVADA' && !iten.hasSignature)"
                                             @click="setFile(iten.id)">
                                            <i class="icon-attach_file mr-2 font-15"/>{{
                                                t('PROPOSAL.SOLICIT_ASSIGN_MANUAL')
                                            }}
                                        </div>
                                        <div class="dropdown-item pointer text-white"
                                             v-if="(iten.status === 'EM AVALIAÇÃO'  && hasConfig && canSocityClickSign(iten?.click_sign_documents?.status)) ||
                             (iten.status === 'APROVADA' && hasConfig && canSocityClickSign(iten?.click_sign_documents?.status))"
                                             @click="solicitClickSing(iten.id)">
                                            <i class="icon-border_color mr-2 font-15"/>{{
                                                t('PROPOSAL.SOLICIT_CLICKSIGN')
                                            }}
                                        </div>
                                        <div class="dropdown-item pointer text-white"
                                             v-if="(iten.status === 'EM AVALIAÇÃO' && !isRealtor()  && hasConfig && iten.click_sign_documents?.status === 1 && !isAssingManual(iten.click_sign_documents)) ||
                             (iten.status === 'APROVADA' && !isRealtor()  && hasConfig && iten.click_sign_documents?.status === 1 && !isAssingManual(iten.click_sign_documents)) "
                                             @click="cancelClickSing(iten.id)">
                                            <i class="icon-x-circle mr-2 font-15"/>{{ t('PROPOSAL.CANCEL_CLICKSIGN') }}
                                        </div>
                                        <div class="dropdown-item pointer text-white"
                                             data-toggle="modal" data-target="#customModalTwo"
                                             v-if="(iten.click_sign_documents?.clicksign_id || isAssingManual(iten.click_sign_documents)) && (hasConfig || isAssingManual(iten.click_sign_documents))"
                                             @click="viewDocumentClickSing(iten?.click_sign_documents?.clicksign_id, iten)">
                                            <i class="icon-eye mr-2 font-15"/>{{
                                                isAssingManual(iten.click_sign_documents) ? t('PROPOSAL.VIEW_CLICKSIGN') : 'Assinaturas'
                                            }}
                                        </div>
                                        <div class="dropdown-item pointer text-white"
                                             v-if="(iten.status !== 'APROVADA' &&
                                             iten.hasSignature &&
                                             isAssingManual(iten.click_sign_documents) &&
                                             !isRealtor())"
                                             @click="deleteAssignManual(iten.id)">
                                            <i class="icon-trash mr-2 font-15"/>{{
                                                t('PROPOSAL.DELETE_ASSIGN_MANUAL')
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card" v-if="items && (items?.validations || !items?.data?.length)">
            <div class="card-header"></div>
            <div class="card-body pt-0">
                <div class="text-center">
                    <h5>{{ t('PROPOSAL.PROPOSAL_NOT_FOUND') }}</h5>
                </div>
            </div>
        </div>
        <modal :modal-xl="true" :title-modal="modalTitle">
            <template v-slot:title>{{ t(modalTitle) }}</template>
            <edit-proposal-component v-if="modalChoose === 1" :proposal-index="proposal"></edit-proposal-component>
            <view-document-click-sign-component v-if="modalChoose === 2"
                                                :itens-index="documentClickSing"></view-document-click-sign-component>
            <filter-proposal-component v-if="modalChoose === 3" :perPageIndex="perPage"
                                       @search="filterProposal" @close="closeFilter()"></filter-proposal-component>
        </modal>
        <div class="row gutters">
            <div class="col-3" v-if="!widthMd(1000)"></div>
            <div :class="!widthMd(1000) ? 'col-6' : 'col-12'">
                <pagination-component v-if="items && items.data && items.data[0]" :items="items" :to="'/propostas'"
                                      @changePage="index(filter, $event)"></pagination-component>
            </div>
            <div class="col-3" v-if="!widthMd(1000)">
                <div class="row gutters d-flex justify-content-end" v-if="items && items.data && items.data[0]">
                    <div class="col-8">
                        <label>Propostas por página: </label>
                        <Select2 :settings="{width: '100%'}" placeholder="Selecione" @select="changePaginate"
                                 :options="paginate"
                                 id="paginate" name="paginate"
                                 v-model="perPage"
                        ></Select2>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <modal-select-allotment v-if="allotments" :showModal="showAllotmentSelect"
                                    :is-proposal="true"
                                    @showModal="showAllotmentSelect = $event" @allotmentSelected="allotmentSelected">
            </modal-select-allotment>
        </div>
        <upload-modal :uploadFile="uploadFile" @upload="uploadFile = $event"
                      @import="uploadDocument($event)"></upload-modal>
    </div>
</template>

<script>
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import pageHeader from "../../components/layouts/pageHeader";
import Proposal from "../../services/Proposal";
import loadScreenComponent from "../../components/layouts/loadScreenComponent";
import PaginationComponent from "../../components/layouts/PaginationComponent";
import Select2 from 'vue3-select2-component';
import modalSelectAllotment from "../allotment/modalSelectAllotmentComponent.vue";
import {mapState} from "vuex";
import modal from '../../components/modal';
import EditProposalComponent from './EditProposalComponent';
import isReator from '../../mixins/isReator';
import errorMsg from '../../components/errorMsg';
import ViewDocumentClickSignComponent from './ViewDocumentClickSignComponent';
import ConfigClickSign from '../../services/ConfigClickSign';
import FilterProposalComponent from './FilterProposalComponent';
import widthMd from '../../mixins/widthMd';
import uploadModal from '../../components/layouts/uploadModal';
import Drive from '../../services/Drive';

export default {
    name: "indexProposalComponent",
    components: {
        pageHeader,
        ViewDocumentClickSignComponent,
        loadScreenComponent,
        PaginationComponent,
        modalSelectAllotment,
        Select2,
        modal,
        EditProposalComponent,
        FilterProposalComponent,
        uploadModal
    },
    computed: {
        ...mapState(['isLoading', 'hastwoAllotments', 'allotments', 'paginate'])
    },
    mixins: [isReator, errorMsg, widthMd],
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    data() {
        return {
            proposalId: null,
            uploadFile: false,
            perPage: null,
            documentClickSing: [],
            items: {},
            iten: {},
            showAllotmentSelect: false,
            allotmentId: null,
            filter: null,
            hasConfig: false,
            modalChoose: null,
            modalTitle: '',
            proposal: {},
        }
    },
    mounted() {
        this.allotmentId = localStorage.getItem('proposalAllotmentId');
        this.showAllotmentSelect = this.hastwoAllotments == true && (localStorage.getItem('proposalAllotmentId') ? false : true);
        if (this.allotments?.length === 1 || this.allotmentId) {
            this.allotmentId = this.allotmentId ? this.allotmentId : this.allotments[0].id;
            localStorage.setItem('proposalAllotmentId', this.allotmentId);
            this.index();
        }
        this.checkHasConfig();
    },
    methods: {
        exportInXlsx()
        {
            this.$store.commit('changeLoading', true);
            Proposal.indexProposal(this.filter, 1, this.allotmentId, this.perPage, 'xls').then(response => {
                if(!response?.data?.validations && response.data){
                    const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'propostas_export.xls';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.$store.commit('changeLoading', false);
            });
        },
        exportInPdf()
        {
            this.$store.commit('changeLoading', true);
            Proposal.indexProposal(this.filter, 1, this.allotmentId, this.perPage, 'pdf').then(resp => {
                if(!resp?.data?.validations && resp.data){
                    const link = document.createElement('a');
                    link.href = resp.data;
                    link.download = 'exportar_propostas.pdf';
                    document.body.appendChild(link);
                    link.click();
                }
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.$store.commit('changeLoading', false);
            });
        },
        markImpress(iten) {
            this.$swal.fire({
                title: `Realmente deseja marcar a proposta N° ${this.getNumberProposal(iten)}
                como ${iten.is_impress ? 'Não Impressa' : 'Impressa'} ?`,
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: `Sim`,
                cancelButtonText: 'Não',
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.commit('changeLoading', true);
                    Proposal.alterImpress(iten.id).then((resp) => {
                        if (!resp?.data?.validations) {
                            iten.is_impress = resp.data.is_impress;
                        }
                    }).catch(err => this.errorMsg(err)).finally(() => this.$store.commit('changeLoading', false));
                }
            });
        },
        canSocityClickSign(status) {
            if (!status) {
                return true;
            }
            switch (status) {
                case 3:
                case 4:
                case 5:
                case 7:
                    return true;
            }
            return false;
        },
        isAssingManual(item) {
            if (item?.status !== 6) {
                return false;
            }
            return true;
        },
        deleteAssignManual(id) {
            this.$store.commit('changeLoading', true);
            Proposal.deleteProposalAssinedManual(id).then(() => {
                this.toast.success(this.t('GENERAL.MSG.DELETED_SUCCESS'));
                this.index();
            }).catch((err) => {
                this.$store.commit('changeLoading', false);
                if (err?.response?.data?.description) {
                    this.toast.error(err.response.data.description);
                } else {
                    this.errorMsg(err);
                }
            })
        },
        async uploadDocument(file) {
            if (!file) return this.toast.error(this.t('CLIENTS.DOCUMENTS.FILE_EMPTY'));
            this.$store.commit('changeLoading', true);
            let formData = new FormData();
            formData.append('file', file);
            formData.append('context_id', this.proposalId);
            formData.append('context', 'propostas');
            Drive.uploadDocument(formData).then((resp) => {
                if (resp.data.code) {
                    this.toast.success(resp.data.description);
                } else {
                    this.toast.success(this.t('PROPOSAL.SOLICIT_ASSIGN_MANUAL_SUCESS'));
                }
                this.index();
            }).catch(err => {
                this.$store.commit('changeLoading', false);
                if (err?.response?.data?.description) {
                    this.toast.error(err.response.data.description);
                } else {
                    this.errorMsg(err);
                }
            }).finally(() => {
                this.$store.commit('changeLoading', false)
            })
        },
        changePaginate() {
            this.index(this.filter);
        },
        filterProposal(items, filter, allotmentId) {
            this.items = items.data;
            this.filter = filter;
            this.allotmentId = allotmentId;
        },
        closeFilter() {
            document.getElementById('closeX')?.click();
            this.filter = null;
            this.modalChoose = null;
            this.index();
        },
        setModalFilter() {
            this.modalTitle = 'ACTIONS.FILTER';
            this.modalChoose = 3;
        },
        checkHasConfig() {
            ConfigClickSign.index().then(resp => {
                this.hasConfig = !!resp.data?.data?.length;
            }).catch(() => {
                this.hasConfig = false;
            })
        },
        allotmentSelected() {
            this.allotmentId = localStorage.getItem('proposalAllotmentId');
            this.index();
        },
        getStatusClickSing(status) {
            if (!status) {
                return '-';
            }
            switch (status) {
                case 1:
                    return 'Aguardando';
                case 2:
                    return 'Assinado';
                case 3:
                    return 'Expirado';
                case 4:
                    return 'Cancelado';
                case 5:
                    return 'Cancelamento Solicitado';
                case 6:
                    return 'Assinatura Manual Anexada';
                case 7:
                    return 'Assinatura Manual Cancelada';
            }
        },
        viewDocumentClickSing(id, iten) {
            this.$store.commit('changeLoading', true);
            if (iten?.click_sign_documents?.id && this.isAssingManual(iten.click_sign_documents)) {
                Proposal.viewProposalAssinedManual(iten.id).then((response) => {
                    const blob = new Blob([response.data], {type: 'application/pdf'});
                    const url = window.URL.createObjectURL(blob);
                    window.open(url, '_blank');
                    document.getElementById('closeX').click();
                    this.$store.commit('changeLoading', false);
                }).catch((err) => {
                    document.getElementById('closeX').click();
                    this.$store.commit('changeLoading', false);
                    this.errorMsg(err);
                })
                return;
            }
            Proposal.showDocumentClickSign(id).then((resp) => {
                this.modalChoose = 2;
                this.modalTitle = this.t('PROPOSAL.DOCUMENTS');
                resp.data.open = true;
                this.documentClickSing = [resp.data];
                this.$store.commit('changeLoading', false);
            }).catch((err) => {
                this.$store.commit('changeLoading', false);
                if (err.response?.data?.validations?.message) {
                    this.toast.error(err.response.data.validations.message);
                }
            });
        },
        cancelClickSing(id) {
            this.$store.commit('changeLoading', true);
            Proposal.cancelClickSign(id).then(() => {
                this.index();
                this.toast.success('Assinatura Cancelada com sucesso!');
            }).catch((err) => {
                this.$store.commit('changeLoading', false);
                if (err.response?.data?.validations?.message) {
                    this.toast.error(err.response.data.validations.message);
                }
            })
        },
        setFile(id) {
            this.proposalId = id;
            this.uploadFile = true;
        },
        solicitClickSing(id) {
            this.$store.commit('changeLoading', true);
            Proposal.solicitClickSign(id).then(() => {
                this.index();
                this.toast.success('Assinatura de Documento requisitada com sucesso!');
            }).catch((err) => {
                this.$store.commit('changeLoading', false);
                this.errorMsg(err)
            })
        },
        setModalEdit(id) {
            this.modalTitle = 'PROPOSAL.EDIT_PROPOSAL';
            this.modalChoose = 1;
            this.$store.commit('changeLoading', true);
            Proposal.show(id).then((resp) => {
                this.proposal = resp.data;
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.$store.commit('changeLoading', false);
            })
        },
        setModalHoover(id) {
            document.getElementById(id).click();
        },
        toCurrency(value) {
            if (value === null) return 'Indisponível';
            return Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(value);
        },
        changeAllotment({id}) {
            this.modalChoose = null;
            localStorage.removeItem('proposalAllotmentId');
            localStorage.setItem('proposalAllotmentId', id);
            this.index();
        },
        getNumberProposal(iten) {
            let block = '';
            if (iten.properties?.block) {
                block = iten.properties.block.replace(/^0+/, '');
            }
            let lot = '';
            if (iten.properties?.name) {
                lot = iten.properties.name.replace(/^0+/, '');
            }
            if (iten.created_at) {
                let date = iten.created_at?.split('T');
                date = date[1]?.split(':');
                let sec = date[2]?.split('.')
                return `${block}${lot}${date[0]}${date[1]}${sec[0]}`;
            }
            return `${block}${lot}`;
        },
        getNameClient(iten) {
            let name = null;
            iten.clients.forEach((item) => {
                if (!item.clients?.name) return;
                name = name !== null ? item.clients.name + ' ,' + name : item.clients.name;
            });
            if (!name) return 'Nenhum cliente vinculado';
            return name;
        },
        endDateFormat(value, created = false) {
            if (value && !created) {
                let dateAndTime = value.split(' ');
                let date = dateAndTime[0].split('-');
                let time = dateAndTime[1].split(':');
                return `${date[2]}/${date[1]}/${date[0]} às ${time[0]}:${time[1]}`;
            } else if (value && created) {
                let dateAndTime = value.split('T');
                let date = dateAndTime[0].split('-');
                let time = dateAndTime[1].split(':');
                return `${date[2]}/${date[1]}/${date[0]} às ${time[0]}:${time[1]}`;
            }
            return 'Indefinido'
        },
        async setFilterModal() {
            this.$store.commit('changeLoading', true)
            this.indexRealtor(this.allotmentId);
            await this.getLots(this.allotmentId)
            this.indexBlocks(this.allotmentId);
            this.indexStatus(this.allotmentId);
            this.wichModal = 5;
            this.modalTitle = 'ACTIONS.FILTER';
            this.$store.commit('changeLoading', false)
        },
        rejectProposal(item) {
            if (item?.click_sign_documents?.id) {
                this.$swal.fire({
                    title: `Assinatura será cancelada ao rejeitar a proposta N°${this.getNumberProposal(item)}`,
                    text: 'Deseja prosseguir?',
                    showCancelButton: true,
                    showCloseButton: true,
                    confirmButtonText: `Sim`,
                    cancelButtonText: 'Não'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.commit('changeLoading', true);
                        Proposal.rejectProposal(item.id).then(() => {
                            this.toast.success(this.t('PROPOSAL.REJECTE_SUCCESS'));
                            this.index();
                        }).catch(() => {
                            this.$store.commit('changeLoading', false);
                        })
                    }
                });
                return;
            } else {
                this.$swal.fire({
                    title: `Realmente deseja rejeitar a proposta N°${this.getNumberProposal(item)} ?`,
                    text: '',
                    showCancelButton: true,
                    showCloseButton: true,
                    confirmButtonText: `Sim`,
                    cancelButtonText: 'Não'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.commit('changeLoading', true);
                        Proposal.rejectProposal(item.id).then(() => {
                            this.toast.success(this.t('PROPOSAL.REJECTE_SUCCESS'));
                            this.index();
                        }).catch(() => {
                            this.$store.commit('changeLoading', false);
                        })
                    }
                });
            }
        },
        reprintProposal(iten) {
            this.$store.commit('changeLoading', true);
            Proposal.reprintProposal(iten.id).then(resp => {
                this.$store.commit('changeLoading', false);
                let win = window.open();
                win.document.write('' +
                        '<iframe src="' + resp.data + '"' +
                        'frameborder="0" ' +
                        'style="width:100vw; height:100vh; margin: -0.6rem;"' +
                        'allow="fullscreen">' +
                        '</iframe>'
                );
            }).catch(() => {
                this.$store.commit('changeLoading', false)
            })
        },
        translateStatus(status) {
            if (status === 'EM AVALIAÇÃO') return 'Em Avaliação';
            if (status === 'APROVADA') return 'Aprovada';
            if (status === 'REJEITADA') return 'Rejeitada';
            if (status === 'EXPIRADA') return 'Expirada';
            if (status === 'VENDA CANCELADA') return 'Venda Cancelada';
        },
        translateColorStatus(status) {
            if (status === 'EM AVALIAÇÃO') return '#fb7f12';
            if (status === 'APROVADA') return '#3FBF3F';
            if (status === 'REJEITADA') return '#f34646';
            if (status === 'EXPIRADA') return '#e0bb47';
            if (status === 'VENDA CANCELADA') return '#f34646';
        },
        translateColorStatusAssign(status) {
            if (!status) {
                return ''
            }
            if (status === 6 || status === 2) {
                return '#3FBF3F';
            }
            if (status === 1) {
                return '#fb7f12';
            }
            if (status === 3) {
                return '#e0bb47';
            }
            if (status === 4 || status === 7) {
                return '#f34646';
            }
            if (status === 5) {
                return '#e90c66';
            }
        },
        index(filter = null, page = 1, exportIn = null) {
            this.filter = filter;
            this.$store.commit('changeLoading', true);
            Proposal.indexProposal(filter, page, this.allotmentId, this.perPage, exportIn).then(resp => {
                this.items = resp.data;
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.$store.commit('changeLoading', false);
            });
        }
    }
}
</script>

<style scoped>
#dropInfo {
    position: absolute !important;
}

.task-list .task-block .dropdown-menu:before {
    border-bottom: 0px solid #262b31 !important;
}

.aling {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pd-top-left-right {
    padding-top: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.pdt-1 {
    padding-top: 1rem;
}

.dropdown-reserve {
    width: 175px;
    left: 10% !important;
}

@media screen and (max-width: 575px) {
    .pd-top-left-right {
        padding-top: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .dropdown-reserve {
        left: 3% !important;
    }
}
</style>